import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './styles/Login.css';
import { db } from '../Backend/Firebase';
import ReCAPTCHA from 'react-google-recaptcha';
import {Form, Button, } from 'react-bootstrap';


const Login = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [state, setState] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [captchaToken, setCaptchaToken] = useState(null);
  const [showForm, setShowForm] = useState(false);
  

  const navigate = useNavigate();

  const handleCaptcha = (token) => {
    // Send the token to your backend for verification
    setCaptchaToken(token);
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    // Validate empty fields
    if (!name || !email || !state) {
      setErrorMessage('Please fill in all fields.');
      return;
    }


    if (!captchaToken) {
      setErrorMessage('Please complete the CAPTCHA.');
      return;
    }
    
    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }

    try {
      // Save user details to Firestore
      const userDocRef = await addDoc(collection(db, 'beta'), {
        name,
        email,
        state,
      });

      console.log('Document written with ID: ', userDocRef.id);

      setName('');
      setEmail('');
      setState('');
      setErrorMessage(''); // Clear any previous error
      setSuccessMessage('Signed up Successful!');

      // Clear messages after 5 seconds
      setTimeout(() => {
        setSuccessMessage('');
           // Redirect to the home page after successful signup
        setErrorMessage('');
        navigate('/');
      }, 5000);

   

    } catch (error) {
      console.error('Error signing up: ', error.message);
     // Handle Firestore errors
     if (error.code === 'permission-denied') {
      setErrorMessage('Error signing up, permission denied.');
    } else if (error.code === 'unavailable') {
      setErrorMessage('Error signing up, please enter a location.');
    } else {
      setErrorMessage('Error signing up. Please try again.');
    }
    }
  };

  const toggleForm = () => {
    setShowForm(!showForm); // showForm state
  };

  return (
    <div className="main-container">
    {/* Top Navigation */}
    <nav className="nav-container">
      <div className="logo">
        <img src="/assets/Blue BG Logo.png" alt="Plus Tou Logo" />
      </div>
      <button className="apply-button" onClick={() => setShowForm('apply')}>
        Apply To Join
      </button>
    </nav>
      {/* <Row>
      <Col md={7} className="content-container">
      <div className={`content-wrapper ${showForm ? 'hide-content' : 'show-content'}`}>
            <div className="intro-text1-layout">
              <p id='intro-text1'>Bringing introverts together to share common interests</p>
            </div>
            <div className="intro-text2-layout">
              <p id='intro-text2'>Create your meaningful moments today!</p>
            </div>
            <div className={`apply_btn ${showForm ? 'mobile' : ''}`}>
              <Button variant="primary" className="button" onClick={toggleForm}>
                Apply to Join!
              </Button>
            </div>
            <div className="intro-text3-layout">
              <p id='intro-text3'>We make it fun for introverts to get out the house and enjoy intimate connections powered by AI and rewarded through blockchain</p>
            </div>
          </div> */}
           {/* Main Content Section */}
      <div className="content-section">
        <div className="text-content">
          <h1>No More Going Solo...</h1>
          <h2>Make It A Plus Tou Moment!</h2>
          
          <div className="feature-text">
            <p>Find A Friend..</p>
            <p>Find Your Body Double..</p>
            <p>Find Someone To Hangout With..</p>
            <p>Find A Companion..</p>
            
            
          </div>
        </div>

        {/* {/* Background Image*/}
        <div className="background-image">
        <source media="(max-width: 650px)" srcSet="/assets/mobilebg.png" alt = 'background'/>
          <img src="/assets/logoed.png" alt='background' />
        </div>
      </div> 

      <div className="mid-container">
  <div className="mid-text">
  <div className="cards-container">
  <div className="mid-text">
  <h4>We make it fun for introverts to get out the house and enjoy one-on-one connections.</h4>
  </div>
  </div>
          <h3>Share Real Connections Based On Real Interests!</h3>
          </div>
        </div>
    
         {/* Cards Section */}
         <div className="cards-container">
  <div className="cta-text">
  <h3>Find/Create Your Plus Tou Moment Today With Genuine Connections Over Common Interests.</h3>
  </div>
  <div className="cta-text">
    <h4>Sign Up for our Beta!</h4>
  </div>
  
      <div className="cards-section">
        <div className="card">
          <img src="/assets/pholic.png" alt="Pho DC" />
          
        </div>
        <div className="card">
          <img src="/assets/ramen.png" alt="Ramen Time" />
        
        </div>
        <div className="card">
          <img src="/assets/rec.png" alt="Accountability Partner" />
          
        </div>
      </div>
 {/* Sign Up Button */}
 <div className="signup-button-container">
        <button onClick={() => setShowForm(true)}>Sign Up</button>
      </div>
{/* Modal Form */}
        {showForm && ( 
          <div md={6} className={`form-container ${showForm ? 'd-block' : 'd-none'}`}>
          <div className='signup-text'>
            <h1>Sign Up Now</h1>
          </div>
          <Form onSubmit={(e) => handleSignup(e)} className="form">
            <div className="form-field">
              <img src="/assets/name.svg" alt="name icon" className="icon" />
              <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-field">
              <img src="/assets/email.svg" alt="email icon" className="icon" />
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-field">
              <img src="/assets/location.svg" alt="location icon" className="icon" />
              <select
                value={state}
                placeholder="Location"
                onChange={(e) => setState(e.target.value)}
              >
                <option value="">Location</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DC">District of Columbia</option>
                <option value="DE">Delaware</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
            <div className="form-field">
              <ReCAPTCHA
                sitekey="6LdU1KwpAAAAAHXZKq-p4425vxn56UEgo5OA3ogr"
                onChange={handleCaptcha}
              />
            </div>
            <div>
              <Button type="submit" className='signup_btn'>Sign up!</Button>
            </div>
          </Form>
        
          {successMessage && (
            <p className="success-message">{successMessage}</p>
          )}
          {errorMessage && (
            <p className="error-message">{errorMessage}</p>
        )}
            </div>
          )}
        </div>
        </div>
      );
    };

export default Login;